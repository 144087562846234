import { Avatar } from '@mui/material';
import { useTheme } from '@mui/system';
import { useAuth } from 'react-oidc-context';
import { UserProfileExtended } from '../domain';
import { stringAvatar } from './domain';

type Props = {
    width?: number;
    height?: number;
};

const CustomAvatar = ({ width = 60, height = 60 }: Props) => {
    const theme = useTheme();
    const { user } = useAuth();

    const userProfile = user?.profile as UserProfileExtended;

    return userProfile.imageUrl && userProfile.imageUrl !== '' ? (
        <Avatar
            alt={userProfile.name}
            src={userProfile.imageUrl}
            sx={{ width: width, height: height, bgcolor: theme.palette.primary.main }}
            data-testid="avatar-image"
        />
    ) : (
        <Avatar
            alt={userProfile.name}
            sx={{ width: width, height: height, bgcolor: theme.palette.primary.main }}
            data-testid="avatar-initials"
            {...stringAvatar(userProfile.name)}
        />
    );
};

export { CustomAvatar };
