import { useEffect, useRef, useState } from 'react';
import { ask } from './api';
import { ChatMessage } from './domain';

const CHAT_MESSAGES = 'myChat';
const CHAT_KEY = 'chatKey';
const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

const setChatInStorage = (value: ChatMessage[]) => {
    sessionStorage.setItem(CHAT_MESSAGES, JSON.stringify(value));
};

const loadChat = (messages: ChatMessage[], setMessages: (value: ChatMessage[]) => void) => {
    if (!messages || messages.length === 0) {
        const conversationContentJsonText = sessionStorage.getItem(CHAT_MESSAGES);
        conversationContentJsonText && setMessages(JSON.parse(conversationContentJsonText));
    }
};

const useChat = () => {
    const abortControllerRef = useRef<AbortController | null>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [sendQuestion, setSendQuestion] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [chatId, setChatId] = useState<string>(sessionStorage.getItem(CHAT_KEY) || EMPTY_GUID);

    useEffect(() => {
        loadChat(messages, setMessages);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        abortControllerRef.current = new AbortController();

        sendQuestion &&
            messages &&
            messages.length > 0 &&
            (async () => {
                const response = await ask(messages, chatId, abortControllerRef?.current?.signal);
                const messagesResponse = [...messages, response.answer] as ChatMessage[];
                setMessages(messagesResponse);
                setChatInStorage(messagesResponse);
                setChatId(response.conversationId);
                sessionStorage.setItem(CHAT_KEY, response.conversationId);
                setSendQuestion(false);
            })();

        return () => {
            abortControllerRef?.current?.abort();
        };
    }, [messages, sendQuestion]);

    const sendMessage = (value: string) => {
        const messagesWithQuestion = [...messages, { text: value, role: 'user' }] as ChatMessage[];
        setMessages(messagesWithQuestion);
        setChatInStorage(messagesWithQuestion);
        setSendQuestion(true);
    };

    const startNewChat = () => {
        setMessages([]);
        setChatId(EMPTY_GUID);
        sessionStorage.removeItem(CHAT_MESSAGES);
        sessionStorage.removeItem(CHAT_KEY);
    };

    return {
        messages,
        isSendingQuestion: sendQuestion,
        sendMessage,
        startNewChat,
        isLoading,
    };
};

export { useChat };
