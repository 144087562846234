import { getAuthSettings } from '@features/auth';
import '@features/internationalization/i18n';
import { getAppSettings } from '@features/settings';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { App } from './App';

const appSettings = await getAppSettings();

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <AuthProvider {...getAuthSettings(appSettings.auth)}>
            <App
                appInsightKey={appSettings.appInsight.key}
                links={appSettings.links}
                modelService={appSettings.modelService}
            />
        </AuthProvider>
    </StrictMode>
);
