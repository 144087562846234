import { agent } from '@shared/api';
import { TokenCountRequest, TokenCountResponse } from './domain';

export const tokenCounter = (text: string, signal?: AbortSignal) =>
    agent.post<TokenCountRequest, TokenCountResponse>(
        '/api/tokens/count',
        {
            message: text,
        },
        signal
    );
