import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { CustomCodeProps } from './domain';
import { CustomCode } from './components';
import './markdown.scss';

type Props = {
    text: string;
};

const Markdown = ({ text }: Props) => (
    <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        className="custom-markdown"
        components={{
            code: props => <CustomCode {...(props as CustomCodeProps)} />,
        }}
    >
        {text}
    </ReactMarkdown>
);

export { Markdown };
