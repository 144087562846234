import { User } from 'oidc-client-ts';

const getToken = () => {
    const oidcStorage = Object.keys(sessionStorage)
        .filter(key => key.startsWith('oidc.user'))
        .map(key => sessionStorage.getItem(key));

    const idToken =
        oidcStorage && oidcStorage.length === 1 ? User.fromStorageString(oidcStorage[0] as string).access_token : '';

    return idToken;
};

export { getToken };
