import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import LogoRomeu from '@assets/images/romeuBlue.svg?react';

const NoMessages = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const disclaimerTexts = [
        t('chat.noMessagesDisclaimer1'),
        t('chat.noMessagesDisclaimer2'),
        t('chat.noMessagesDisclaimer3'),
    ];

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignContent={'center'}
        >
            <Box height={'100%'} display={'flex'} flexWrap={'wrap'} alignContent={'center'}>
                <LogoRomeu height={300} width={'100%'} />
            </Box>
            <Box
                width={'100%'}
                maxWidth={1024}
                maxHeight={500}
                marginX={'auto'}
                display={'flex'}
                justifyContent={'space-between'}
                alignContent={'start'}
            >
                {disclaimerTexts.map((disclaimerText, i) => (
                    <Box width={'32%'} bgcolor={theme.palette.primary.main} borderRadius={1} padding={2} key={i}>
                        <Typography variant="body2" fontWeight={500} color={'white'}>
                            {disclaimerText}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export { NoMessages };
