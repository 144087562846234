import { produce } from 'immer';
import { getToken } from '../token';

const http = async <T>(path: string, config: RequestInit): Promise<T> => {
    const request = new Request(path);
    const token = getToken();

    const secureConfig = token
        ? produce(config, draft => {
              draft.headers = { ...draft.headers, Authorization: `Bearer ${token}` };
          })
        : config;

    const response = await fetch(request, secureConfig);

    if (!response.ok) {
        switch (response.status) {
            case 401:
                throw new Error(response.statusText, {
                    cause: response.status,
                });
            case 406:
                throw new Error('contentFilter', {
                    cause: await response.json(),
                });
            case 413:
                throw new Error('maximumContent', {
                    cause: await response.json(),
                });
            default:
                throw new Error('errorMessageGenericAction', {
                    cause: response.status,
                });
        }
    }

    return response.json().catch(() => {});
};

const defaultConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export type Agent = {
    get: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    post: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    put: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    delete: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
};

export const agent: Agent = {
    get: <T>(path: string, signal?: AbortSignal): Promise<T> =>
        http<T>(path, { method: 'GET', ...defaultConfig, signal: signal }),
    post: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'POST', ...defaultConfig, body: JSON.stringify(body), signal: signal }),
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'POST', ...defaultConfig, body: body as BodyInit, signal: signal, headers: {} }),
    put: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'PUT', body: JSON.stringify(body), ...defaultConfig, signal: signal }),
    delete: <T>(path: string, signal?: AbortSignal): Promise<T> =>
        http<T>(path, { method: 'DELETE', ...defaultConfig, signal: signal }),
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, {
            ...defaultConfig,
            method: 'PATCH',
            body: body ? JSON.stringify(body) : JSON.stringify({}),
            signal: signal,
        }),
};
