import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tokenCounter } from './api';

type Props = {
    maxTokens: number;
    tokenCounterInfoUrl: string;
    text: string;
    setDisableSendingQuestion: (value: boolean) => void;
};

const TokenCounter = ({ maxTokens, text, tokenCounterInfoUrl, setDisableSendingQuestion }: Props) => {
    const abortControllerRef = useRef<AbortController | null>(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const [usedTokens, setUsedTokens] = useState(0);

    useEffect(() => {
        if (!text.length) {
            setUsedTokens(0);
            setDisableSendingQuestion(false);
            return () => {};
        }

        const countTokenDebounced = setTimeout(async () => {
            abortControllerRef.current = new AbortController();
            const response = await tokenCounter(text, abortControllerRef?.current?.signal);
            setUsedTokens(response.count);
            response.count > maxTokens ? setDisableSendingQuestion(true) : setDisableSendingQuestion(false);
        }, 500);

        return () => {
            clearTimeout(countTokenDebounced);
            abortControllerRef?.current?.abort();
        };
    }, [text]);

    return (
        <Box width="100%" paddingX={2} paddingBottom={1} display="flex" justifyContent="end" gap={0.5}>
            <Typography variant="body2" fontSize={12}>
                {`${t('chat.tokenCount')}`}
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    component="span"
                    marginLeft={0.5}
                    fontSize={12}
                    color={usedTokens > maxTokens ? theme.palette.error.main : 'inherit'}
                >
                    {usedTokens}
                </Typography>
                <Typography variant="body2" fontWeight="bold" component="span" fontSize={12}>
                    {`/${maxTokens}`}
                </Typography>
            </Typography>
            {tokenCounterInfoUrl && tokenCounterInfoUrl !== '' ? (
                <Tooltip title={`${t('general.goTo')} ${tokenCounterInfoUrl}`} placement="top" data-testid="tooltip">
                    <Link href={tokenCounterInfoUrl} target="_blank">
                        <InfoOutlinedIcon sx={{ width: 16, height: 16, color: theme.palette.primary.main }} />
                    </Link>
                </Tooltip>
            ) : null}
        </Box>
    );
};

export { TokenCounter };
