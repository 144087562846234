import { t } from 'i18next';

const ChatErrorManagement = () => {
    const manageError = (error: Error) => {
        switch (error.message) {
            case 'contentFilter':
                return {
                    answer: {
                        role: 'error',
                        text: t('errors.contentFilter'),
                    },
                    conversationId: '',
                };
            case 'maximumContent':
                return {
                    answer: {
                        role: 'error',
                        text: t('errors.tokenExceed'),
                    },
                    conversationId: '',
                };
            default:
                return {
                    answer: {
                        role: 'error',
                        text: t('errors.genericMessage'),
                    },
                    conversationId: '',
                };
        }
    };

    return { manageError };
};

export { ChatErrorManagement };
