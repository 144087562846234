import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const configureAppInsights = (key: string) => {
    const reactPlugin = new ReactPlugin();

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: key,
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            extensions: [reactPlugin],
        },
    });
    appInsights.loadAppInsights();

    return {
        reactPlugin,
        appInsights,
    };
};

export { configureAppInsights };
