import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const spin = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(5px);
  }
`;

const WritingPoints = () => (
    <Box data-testid="points-content" display="flex" width="100%">
        <Box
            sx={{
                animation: `${spin} 1s infinite ease`,
            }}
        >
            .
        </Box>
        <Box
            sx={{
                animation: `${spin} 1s infinite ease 0.25s`,
            }}
        >
            .
        </Box>
        <Box
            sx={{
                animation: `${spin} 1s infinite ease 0.75s`,
            }}
        >
            .
        </Box>
    </Box>
);
export { WritingPoints };
