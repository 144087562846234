import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { Role } from '../domain';
import { Markdown } from '@shared/ui';

type Props = {
    role: Role;
    text: string;
};

const MessageElement = ({ role, text }: Props) => {
    const theme = useTheme();

    return role === 'assistant' ? (
        <Box marginTop={1} width="calc(100% - 56px)" sx={{ wordBreak: 'break-word' }}>
            <Markdown text={text} />
        </Box>
    ) : (
        <Box
            marginTop={1}
            width="calc(100% - 56px)"
            color={role === 'error' ? theme.palette.error.main : 'inherit'}
            sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
            {text}
        </Box>
    );
};

export { MessageElement };
