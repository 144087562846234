import { agent } from '@shared/api';
import { ChatErrorManagement } from './chatErrorManagement';
import { ChatMessage, ConversationRequest, ConversationResponse } from './domain';

export const ask = async (messages: ChatMessage[], conversationId: string, signal?: AbortSignal) => {
    const { manageError } = ChatErrorManagement();

    try {
        const response = await agent.post<ConversationRequest, ConversationResponse>(
            '/api/ask',
            {
                conversation: { messages: messages },
                id: conversationId,
            },
            signal
        );

        return response;
    } catch (error) {
        return manageError(error as Error);
    }
};
