import en from '@assets/locals/en.json';
import es from '@assets/locals/es.json';
import i18next, { i18n as i18nInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const Language = {
    English: 'en',
    Spanish: 'es',
    EnglishUS: 'en-US',
    EnglishGB: 'en-GB',
    SpanishES: 'es-ES',
} as const;

const resources = {
    [Language.Spanish]: {
        translation: es,
    },
    [Language.English]: {
        translation: en,
    },
};

const i18nextDetectionsOptions = {
    order: ['navigator'],
};

const i18nextOptions = {
    resources,
    debug: false,
    returnNull: false,
    fallbackLng: Language.English,
    supportedLngs: [Language.SpanishES, Language.EnglishGB, Language.EnglishUS, Language.Spanish, Language.English],
    interpolation: {
        escapeValue: false,
    },

    detection: i18nextDetectionsOptions,
};

const createI18n = (): i18nInstance => {
    const i18n = i18next.use(LanguageDetector).use(initReactI18next);
    i18n.init(i18nextOptions);
    return i18n;
};

export const i18n = createI18n();
