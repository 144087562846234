import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TokenCounter } from '../tokenCounter';

type Props = {
    sendMessage: (value: string) => void;
    isSendingQuestion: boolean;
    maxTokens: number;
    tokenCounterInfoUrl: string;
    setDivMessageBoxHeight: (value: number) => void;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
};

const MessageBox = ({
    sendMessage,
    isSendingQuestion,
    maxTokens,
    tokenCounterInfoUrl,
    setDivMessageBoxHeight,
    isFocused,
    setIsFocused,
}: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [message, setMessage] = useState('');
    const [disableSendingQuestion, setDisableSendingQuestion] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const divWrapperRef = useRef<HTMLDivElement>(null);

    const clickHandler = () => {
        const messageTrimmed = message.trim();
        if (messageTrimmed !== '') {
            sendMessage(messageTrimmed);
            setMessage('');
            setIsFocused(true);
        }
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isReady = !isSendingQuestion && !disableSendingQuestion;
        const sendKeyPressed = e.key === 'Enter' && !e.shiftKey;
        if (sendKeyPressed && isReady) {
            clickHandler();
            e.preventDefault();
        }
    };

    const disableSendButton = isSendingQuestion || disableSendingQuestion || message === '';

    useLayoutEffect(() => {
        if (isFocused && !isSendingQuestion) {
            inputRef?.current?.focus();
            setIsFocused(false);
        }
    }, [isFocused, isSendingQuestion]);

    useLayoutEffect(() => {
        setDivMessageBoxHeight(divWrapperRef.current!.scrollHeight);
    }, [message]);

    return (
        <Box
            maxWidth={1024}
            minHeight={72}
            bgcolor={grey[300]}
            margin="auto"
            borderRadius={1}
            bottom={0}
            alignSelf="end"
            position="fixed"
            right={0}
            left={0}
            marginBottom={6}
            ref={divWrapperRef}
        >
            <Box display="flex" alignItems="center" width="100%" position="relative">
                <TextField
                    variant="standard"
                    multiline
                    fullWidth
                    placeholder={t('chat.textfieldPlaceholder')}
                    data-testid="text-field-message-box"
                    sx={{
                        bgcolor: grey[300],
                        minHeight: 52,
                        maxHeight: 250,
                        overflow: 'auto',
                        padding: 2,
                        paddingRight: 9,
                        paddingBottom: 0,
                        marginBottom: 1,
                        borderRadius: 1,
                    }}
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={keyDownHandler}
                    value={message}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    disabled={isSendingQuestion}
                    autoFocus
                    inputRef={inputRef}
                />
                <Button
                    type="submit"
                    onClick={clickHandler}
                    sx={{
                        minWidth: 40,
                        maxHeight: 40,
                        minHeight: 40,
                        marginX: 2,
                        position: 'absolute',
                        bottom: 8,
                        right: 0,
                        bgcolor: theme.palette.primary.main,
                        '&:hover': { bgcolor: theme.palette.primary.dark },
                        '&:disabled': { bgcolor: grey[400] },
                    }}
                    disabled={disableSendButton}
                >
                    <SendIcon sx={{ color: 'white' }} />
                </Button>
            </Box>

            <TokenCounter
                maxTokens={maxTokens}
                text={message}
                tokenCounterInfoUrl={tokenCounterInfoUrl}
                setDisableSendingQuestion={setDisableSendingQuestion}
            />
        </Box>
    );
};

export { MessageBox };
