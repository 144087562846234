import { MessageBox, MessagesList, NewChat, NoMessages, useChat } from '@features/chat';
import { Footer } from '@features/chat/footer/Footer';
import { Header } from '@features/header';
import { LinksSettings, ModelServiceSettings } from '@features/settings';
import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';

const MESSAGE_BOX_INITIAL_HEIGHT = 94;

type Props = {
    links: LinksSettings;
    modelService: ModelServiceSettings;
};

const ChatPage = ({ links, modelService }: Props) => {
    const { messages, isSendingQuestion, isLoading, sendMessage, startNewChat } = useChat();
    const [divMessageBoxHeight, setDivMessageBoxHeight] = useState<number>(MESSAGE_BOX_INITIAL_HEIGHT);
    const [messageBoxFocus, setMessageBoxFocus] = useState<boolean>(false);

    const handleStartNewChat = () => {
        startNewChat();
        setMessageBoxFocus(true);
    };

    return (
        <>
            <Header />
            <Box
                height={`calc(100vh - (${divMessageBoxHeight}px + 124px))`}
                display="flex"
                justifyContent="start"
                flexDirection="column"
                alignItems="center"
                marginTop={8}
                overflow="auto"
            >
                {!isLoading ? (
                    messages.length > 0 ? (
                        <MessagesList messages={messages} isSendingQuestion={isSendingQuestion} />
                    ) : (
                        <NoMessages />
                    )
                ) : (
                    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress sx={{ minWidth: 100, minHeight: 100 }} />
                    </Box>
                )}
                <MessageBox
                    sendMessage={sendMessage}
                    isSendingQuestion={isSendingQuestion}
                    maxTokens={modelService.maxTokens}
                    tokenCounterInfoUrl={modelService.urlTokenCounterInfo}
                    setDivMessageBoxHeight={setDivMessageBoxHeight}
                    isFocused={messageBoxFocus}
                    setIsFocused={setMessageBoxFocus}
                />
                <Footer links={links} />
                <NewChat startNewChat={handleStartNewChat} isDisabled={messages.length === 0} />
            </Box>
        </>
    );
};
export { ChatPage };
