import { createTheme } from '@mui/material';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#4eba71',
            light: '#82eda0',
            dark: '#098945',
        },
        secondary: {
            main: '#0d1c36',
            dark: '#000011',
            light: '#374260',
        },
        error: {
            main: '#db202f',
        },
        text: {
            primary: '#101010',
            secondary: '#222',
            disabled: '#777',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        subtitle1: {
            fontFamily: 'Open Sans',
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        subtitle2: {
            fontFamily: 'Open Sans',
            fontSize: '1rem',
            fontWeight: 400,
        },
        body1: {
            fontFamily: 'Raleway',
        },
        caption: {
            fontFamily: 'Raleway',
        },
        overline: {
            fontFamily: 'Open Sans',
        },
    },
});

export { customTheme };
