import ErrorOutlineOutlinedIcon from '@assets/images/errorPageIcon.svg?react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import { Header } from '@features/header';

type Props = {
    title?: string;
    subtitle?: string;
};

const ErrorPage = ({ title, subtitle }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Header />
            <Box
                width="100%"
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Box sx={{ width: { md: 220, xl: 320 }, height: { md: 220, xl: 320 } }}>
                    <ErrorOutlineOutlinedIcon width="100%" height="100%" />
                </Box>
                <Typography variant="h5" color={theme.palette.primary.main}>
                    {title ?? t('errors.page.title')}
                </Typography>
                <Typography variant="h6" fontSize={36}>
                    {subtitle ?? t('errors.page.subtitle')}
                </Typography>
                <Button
                    variant="contained"
                    sx={{ marginTop: { md: 4, xl: 8 } }}
                    disableElevation
                    href="/"
                    data-testid="error-page-button"
                >
                    <Typography variant="button" color="white">
                        {t('errors.page.button')}
                    </Typography>
                </Button>
            </Box>
        </>
    );
};

export { ErrorPage };
