import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    textToCopy: string;
    buttonClassName?: string;
    translationKey?: string;
    disabled?: boolean;
};

const CopyButton = ({ textToCopy, buttonClassName, translationKey, disabled = false }: Props) => {
    const { t } = useTranslation();
    const copyTitle = t('general.copy');
    const [copied, setCopied] = useState<boolean>(false);
    const theme = useTheme();

    const copyToClipboardHandler = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <IconButton
            type="button"
            className={buttonClassName}
            onClick={copyToClipboardHandler}
            disabled={disabled}
            id="copy-button"
            title={copyTitle}
            data-testid="copy-button"
        >
            {copied ? (
                <CheckIcon sx={{ fontSize: 20, color: theme.palette.primary.main }} />
            ) : (
                <ContentCopyIcon sx={{ fontSize: 20, color: theme.palette.primary.main }} />
            )}
            <span>{translationKey ? t(translationKey) : ''}</span>
        </IconButton>
    );
};

export { CopyButton };
