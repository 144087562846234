const stringAvatar = (userName: string) => {
    const usernameSplit = userName.split(' ');
    const name = usernameSplit[0]?.trim().split(' ')[0];
    const surname = usernameSplit[1]?.trim();

    return {
        children: `${name?.charAt(0).toUpperCase()}${surname?.charAt(0).toUpperCase()}`,
    };
};

export { stringAvatar };
