import { LinksSettings } from '@features/settings';
import { Box, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

type Props = {
    links: LinksSettings;
};

const Footer = ({ links }: Props) => {
    const { t } = useTranslation();

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            gap={1}
            position={'fixed'}
            right={0}
            left={0}
            bottom={0}
            marginBottom={2}
        >
            <Typography borderRight={1} borderColor={grey[300]} paddingRight={1} variant="body2">
                {t('chat.footer.text')}
            </Typography>
            <Link borderRight={1} borderColor={grey[300]} paddingRight={1} href={links.privacyPolicy} target="_blank">
                <Typography variant="body2">{t('chat.footer.privacyPolicy')}</Typography>
            </Link>
            <Link href={links.legalWarning} target="_blank">
                <Typography variant="body2">{t('chat.footer.legalNotice')}</Typography>
            </Link>
        </Box>
    );
};

export { Footer };
