import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    cancelHandler: () => void;
    acceptHandler: () => void;
};

const ConfirmationButtons = ({ cancelHandler, acceptHandler }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <Button variant="outlined" onClick={cancelHandler}>
                {t('general.cancel')}
            </Button>
            <Button variant="contained" onClick={acceptHandler} sx={{ color: 'white' }}>
                {t('general.accept')}
            </Button>
        </>
    );
};

export { ConfirmationButtons };
