import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { ChatMessage } from '../domain';
import { MessageContent } from '../messageContent';

type Props = {
    messages: ChatMessage[];
    isSendingQuestion: boolean;
};

const MessagesList = ({ messages, isSendingQuestion }: Props) => {
    const bottomRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <Box width={'100%'}>
                {messages.map((message, i) => {
                    return <MessageContent key={i} chatMessage={message} isSendingQuestion={false} />;
                })}
                {isSendingQuestion ? (
                    <MessageContent
                        key="loading"
                        chatMessage={{ text: '', role: 'loading' }}
                        isSendingQuestion={true}
                    />
                ) : null}
            </Box>
            <div ref={bottomRef} />
        </>
    );
};

export { MessagesList };
