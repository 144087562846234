import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CustomCodeProps } from '../domain';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CustomCode = ({ inline, className, children, ...props }: CustomCodeProps) => {
    const match = /language-(\w+)/.exec(className || '');

    return !inline && match && match.length > 1 ? (
        <SyntaxHighlighter style={a11yDark} PreTag="div" language={match[1]} {...props}>
            {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
    ) : (
        <code className="custom-code-no-language" {...props}>
            {children}
        </code>
    );
};

export { CustomCode };
