import { AuthSettings } from '@features/settings';
import { WebStorageStateStore } from 'oidc-client-ts';

const BASE_PATH = '/';

const onSigninCallback = () => {
    window.location.href = BASE_PATH;
 };


const getAuthSettings = (settings: AuthSettings) => ({
    ...settings,
    loadUserInfo: true,
    revokeTokensOnSignout: true,
    onSigninCallback,
    useStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: settings.silentRenew,
});

export { getAuthSettings };
