import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LinksSettings, ModelServiceSettings } from '@features/settings';
import { LinearProgress } from '@mui/material';
import { ChatPage } from '@pages/ChatPage';
import { RouteGuard } from './RouteGuard';

type Props = {
    links: LinksSettings;
    modelService: ModelServiceSettings;
};

const ApplicationRoutes = ({ links, modelService }: Props) => (
    <Routes>
        <Route
            element={
                <Suspense fallback={<LinearProgress />}>
                    <RouteGuard />
                </Suspense>
            }
        >
            <Route index element={<ChatPage links={links} modelService={modelService} />} />
        </Route>
    </Routes>
);

export { ApplicationRoutes };
